export default {
  
  init() {
    $('#slider-cont').slick({
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: 'ondemand',
      fade: true,
      arrows: true,
      prevArrow: "<i class='fa fa-angle-left slick-prev' aria-hidden='true'></i><span class='sr-only'>Previous</span>",
      nextArrow: "<i class='fa fa-angle-right slick-next' aria-hidden='true'></i><span class='sr-only'>Next</span>",
      cssEase: 'linear',
      speed: 1500,
      autoplay: true,
      autoplaySpeed: 6000,
      pauseOnHover: false
    });
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
