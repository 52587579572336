export default {

  init() {
    // Copyright 2014-2015 Twitter, Inc.
    // Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
    if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
      var msViewportStyle = document.createElement('style')
      msViewportStyle.appendChild(
        document.createTextNode(
          '@-ms-viewport{width:auto!important}'
        )
      )
      document.querySelector('head').appendChild(msViewportStyle)
    }
    //smooth scroll
    function setSmoothScroll() {
      $('a[href*=#]:not([href=#])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
          if (target.length) {
            $('html,body').animate({
              scrollTop: target.offset().top
            }, 1000);
            return false;
          }
        }
      });
    }

    function updateZeeTables() {
      var url = window.location.href;
      if (url.indexOf("/Admin/") == -1) {
        $("#subpage-content table").each(function (n) {
          if (!$(this).hasClass("no-format") && !$(this).hasClass("month_selection") && !$(this).hasClass("calendar_table") && !$(this).hasClass("subhead-table-cont") && !$(".form_result").length) {
            $(this).wrap("<div class='table-responsive'>").addClass("table table-striped");
            $(this).parent().before("<div class='alert alert-info visible-xs alert-dismissable'><button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>Scroll Table <i class='fa fa-long-arrow-right'></i></div>");
          }
        });
      }
    }

    function setTheMenu() {
      if ($(".test-class-mobile").css("float") == "left") {
        $("a[href='/search']").unbind("click").click(function (n) {
          n.preventDefault(); n.stopPropagation();
          $("#search-cont").toggle();
        });
        $(".navbar-toggle").click(function () {
          $(this).toggleClass("open");
        });
        $(".dropdown").hover(
          function () {
            $('.dropdown-menu', this).stop(true, true).fadeIn("fast");
            $(this).toggleClass('open');
            $('b', this).toggleClass("caret caret-up");
          },
          function () {
            $('.dropdown-menu', this).stop(true, true).fadeOut("fast");
            $(this).toggleClass('open');
            $('b', this).toggleClass("caret caret-up");
          }).click(function () {
            window.location = $(this).find("a").first().attr("href");
          });
      }
      else {
        $(".search-toggle-cont a").unbind("click").click(function () {
          window.location.href = "/Search-Results";
        });
        $(".navbar-toggle").unbind("click");
        $(".dropdown").unbind('mouseenter mouseleave click');
      }
    }

    function setSubpageHeight() {
      if ($("#subpage-hero-cont").length) {
        var myImg = $("#sub-img-hero img");
        myImg.one("load", function () {
          var myHeight = myImg.outerHeight();
          $("#subpage-hero-cont table td, .shadow").css("height", myHeight + "px");
        }).each(function () {
          if (this.complete) $(this).load();
        });

      }
    }
    
    function setEqualHeightBlog() {
      if ($(".blog-image").length) {
        $(".blog-image").each(function () {
            var blogHeight = $(this).parent(".blog-row").find(".blog-height");
            var rowHeight = parseInt($(blogHeight).outerHeight());
            $(this).css("height", rowHeight + "px");
        });
      }
    }

    function setHomePageShadowHeight() {
      var myHeight = $("#dnn_heroPane img").outerHeight();
      $(".shadow").css("height", myHeight + "px");
    }

    function hideCaptions() {
      $('.slide-caption:has(h1:empty)').remove();
    }

    $(document).ready(function () {
      //setHomePageShadowHeight();
      //init
      setTheMenu();
      setSubpageHeight();
      setEqualHeightBlog();
      updateZeeTables();
      setSmoothScroll();
      hideCaptions();
    });

    $(window).load(function () {

    });

    $(window).resize(function () {
      setSubpageHeight();
      setEqualHeightBlog();
      //setHomePageShadowHeight();
      setTheMenu();
      hideCaptions();
    });


  },

  finalize() {

  },

};
