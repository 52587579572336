//style
import '../scss/style.scss';
//bootstrap default js functionality
import 'bootstrap-sass/assets/javascripts/bootstrap.js';
//slick
import slick from 'slick-carousel';
//router for specific page js
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
// import then needed Font Awesome functionality
import { library, dom } from '@fortawesome/fontawesome-svg-core';
// import the Facebook and Twitter icons
import { faTwitter, faFacebookF, faLinkedinIn, faGooglePlusG, faPinterestP, faTumblr } from "@fortawesome/free-brands-svg-icons";
import { faSearch, faCaretRight, faBars, faTimes, faEnvelope, faChevronRight, faChevronLeft, faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
// add the imported icons to the library
library.add(faTwitter, faSearch, faCaretRight, faBars, faTimes, faEnvelope, faFacebookF, faLinkedinIn, faGooglePlusG, faPinterestP, faTumblr, faChevronRight, faChevronLeft, faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faLongArrowAltRight);
// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();
/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  /** All pages */
  common,
  /** First page */
  home
  /** Custom */

});
/** Load Events */
jQuery(document).ready(() => routes.loadEvents());